import axios from 'axios';
import { getToken } from '../utility/helpers';
import { decrypt, encrypt } from '../utility/encryption';

export const defaultHeader = {
  Accept: 'application/json',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
};

export async function request(url, payload, method, header, options = {}) {
  delete defaultHeader.Authorization;
  if (options?.isFormData) delete defaultHeader['Content-Type'];
  if (options?.isPdf) defaultHeader['Content-Type'] = 'application/pdf';
  if (options?.isCsv) defaultHeader['Content-Type'] = 'text/csv';

  const requestMethod = method.toLowerCase();

  const persistedToken = getToken();

  if (persistedToken) {
    defaultHeader.Authorization = `Bearer ${persistedToken}`;
  }

  return axios
    .request({
      url,
      method: requestMethod,
      headers: { ...defaultHeader, ...header },
      responseType: !options.isDownload && !options.isPdf ? 'json' : 'blob',
      // [requestMethod === 'get' ? 'params' : 'data']: options?.shouldEncrypt
      //   ? { payload: encrypt(payload), encrypted: true }
      //   : { payload: payload, encrypted: false },
      ...(requestMethod === 'get'
        ? { params: payload }
        : {
            data: options?.shouldEncrypt
              ? { payload: encrypt(payload), encrypted: true }
              : { payload: payload, encrypted: false },
          }),
    })
    .then(({ data }) => data)
    .then((jsonResponse) => {
      if (!jsonResponse.arrayBuffer) {
        if (jsonResponse?.encrypted) {
          const decryptedData = decrypt(jsonResponse?.data);

          return {
            ...jsonResponse,
            data: decryptedData || jsonResponse?.data,
          };
        }
        return jsonResponse;
      }

      if (options.isDownload) {
        const objectUrl = window.URL.createObjectURL(jsonResponse);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = options.fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        return window.URL.createObjectURL(jsonResponse);
      }

      return null;
    })
    .catch((errorResponse) => {
      if (errorResponse.message === 'Network Error') {
        errorResponse.message = 'Network connection error';
      }

      return new Promise((_, reject) => {
        reject(errorResponse.response?.data);
      });
    });
}
